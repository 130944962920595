import React, { useState } from 'react';

// import NavbarSection from './components/NavbarSection';
// import HeroSection from './components/HeroSection';
// import About from './components/About';
// import Services from './components/Services';
// import CoursesOffer from './components/CoursesOffer';
// import Development from './components/Development';
// import DigitalMarketing from './components/DigitalMarketing';
// import Testimonial from './components/Testimonial';
// import Contact from './components/Contact';
// // import Footer from './components/Footer';

const App = () => {
  const [email, setEmail] = useState("");  // State to store the email input
  const [message, setMessage] = useState("");  // State to store any validation or success message

  // Function that handles form submission
  const handleSubscribe = (e) => {
    e.preventDefault();  // Prevent the form from submitting and refreshing the page
    
    // Validate the email format using a simple regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!emailPattern.test(email)) {
      setMessage("Please enter a valid email address.");  // Show error message if the email is invalid
    } else {
      setMessage("");  // Clear any previous error message
      alert("Thank you for subscribing!");  // Show success alert
      setEmail("");  // Clear the email input field after successful subscription
    }
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.heading}>Coming Soon!</h1>
          <p style={styles.text}>
            Our website is under development. Stay tuned for something amazing!
          </p>
          <form style={styles.form} onSubmit={handleSubscribe}>
            <input
              type="email"
              placeholder="Enter your email"
              style={styles.input}
              value={email}  // Bind the input value to the email state
              onChange={(e) => setEmail(e.target.value)}  // Update the email state as the user types
            />
            <button 
              type="submit"  // Ensure that the form submission triggers handleSubscribe
              style={styles.button}
            >
              Notify Me
            </button>
          </form>
          {/* Conditionally render the message (error or success) */}
          {message && <p style={styles.errorMessage}>{message}</p>}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f4f4f9",
    margin: 0,
  },
  card: {
    backgroundColor: "#ffffff",
    padding: "2rem",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: "400px",
    width: "100%",
  },
  heading: {
    fontSize: "2rem",
    color: "#333",
    marginBottom: "1rem",
  },
  text: {
    fontSize: "1rem",
    color: "#555",
    marginBottom: "1.5rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  input: {
    padding: "0.75rem",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    outline: "none",
    width: "100%",
  },
  button: {
    padding: "0.75rem",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    fontSize: "1rem",
    cursor: "pointer",
  },
  errorMessage: {
    color: "red",  // Style for the error message
    marginTop: "1rem",
    fontSize: "1rem",
  },
};

export default App;


































// import React, { useRef } from 'react';
// import NavbarSection from './components/NavbarSection';
// import HeroSection from './components/HeroSection';
// import About from './components/About';
// import Services from './components/Services';
// import CoursesOffer from './components/CoursesOffer';
// import Development from './components/Development';
// import DigitalMarketing from './components/DigitalMarketing';
// import Testimonial from './components/Testimonial';
// import Contact from './components/Contact';
// // import Footer from './components/Footer';


// const App = () => {
//   const sectionRefs = {
//     hero: useRef(null),
//     about: useRef(null),
//     services: useRef(null),
//     coursesOffer: useRef(null),
//     development: useRef(null),
//     digitalMarketing: useRef(null),
//     testimonial: useRef(null),
//     contact: useRef(null),
//     // footer: useRef(null),
//   };

//   const handleNavigate = (section) => {
//     if (sectionRefs[section]?.current) {
//       sectionRefs[section].current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <div>
     
//       {/* Navbar */}
//       <NavbarSection onNavigate={handleNavigate} />

//       {/* Container for sections */}
//       <div className="px-4 sm:px-6 md:px-20">
//         {Object.entries({
//           hero: <HeroSection />,
//           about: <About />,
//           services: <Services />,
//           coursesOffer: <CoursesOffer />,
//           development: <Development />,
//           digitalMarketing: <DigitalMarketing />,
//           testimonial: <Testimonial />,
//           contact: <Contact />,
//           // footer: <Footer />,
//         }).map(([key, component]) => (
//           <div key={key} ref={sectionRefs[key]}>
//             {component}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default App;



// // import React, { useState, useEffect } from "react";
// // import { Navigation } from "./components/navigation";
// // import { Header } from "./components/header";
// // import { Features } from "./components/features";
// // import { About } from "./components/about";
// // import { Services } from "./components/services";
// // import { Gallery } from "./components/gallery";
// // import { Testimonials } from "./components/testimonials";
// // import { Team } from "./components/Team";
// // import { Contact } from "./components/contact";
// // import JsonData from "./data/data.json";
// // import SmoothScroll from "smooth-scroll";
// // import "./App.css";



// // export const scroll = new SmoothScroll('a[href*="#"]', {
// //   speed: 1000,
// //   speedAsDuration: true,
// // });

// const App = () => {
//   // const [landingPageData, setLandingPageData] = useState({});
//   // useEffect(() => {
//   //   setLandingPageData(JsonData);
//   // }, []);

//   return (
//     // <div>
//     //   <Navigation />
//     //   <Header data={landingPageData.Header} />
//     //   <Features data={landingPageData.Features} />
//     //   <About data={landingPageData.About} />
//     //   <Services data={landingPageData.Services} />
//     //   <Gallery data={landingPageData.Gallery} />
//     //   <Testimonials data={landingPageData.Testimonials} />
//     //   <Team data={landingPageData.Team} />
//     //   <Contact data={landingPageData.Contact} />
//     // </div>
//     <h1>Thank you for visiting our website. We are currently working on building and improving this site to provide you with the best experience. Please check back soon. We appreciate your patience and understanding.</h1>
//   );
// };

// export default App;
